<template>
  <div>
    <div>
      <Sidebar
        title="Reports"
        :visible.sync="visible"
        :contentStyle="{ overflow: 'visible' }"
        position="right"
        style="max-width: 500px"
      >
        <h1><i class="fas fa-filter" />Choose Report</h1>
        <br />
        <div class="side-bar-item">
          <Dropdown
            v-model="selectedReport"
            :options="reportOptions"
            optionLabel="name"
            placeholder="Select Report"
            @change="loadReport"
          />
        </div>
        <br />
        <div class="side-bar-item">
          <Button
            label="delete record"
            icon="pi pi-trash"
            iconPos="right"
            @click="deleteRecord"
            class="p-button-report-light"
          />
        </div>
      </Sidebar>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card">
          <Toolbar class="p-toolbar-report">
            <template slot="start">
              <h1>{{ selectedReport.name }}</h1>
            </template>
            <template slot="end">
              <Button
                icon="pi pi-filter"
                @click="visible = !visible"
                class="p-button-report-light"
              />
            </template>
          </Toolbar>
          <GmapMap
            v-if="!loading"
            :center="center"
            :zoom="7"
            style="width: 100%; height: 80vh"
          >
            <Gmap-info-window
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <span v-for="(data, key) in reportData" v-bind:key="key"
                ><b>{{ data.key }}:</b> {{ data.value }}<br
              /></span>
            </Gmap-info-window>
            <GmapCluster zoomOnClick="true">
              <GmapMarker
                :key="index"
                :position="item"
                :icon="getIcon(item.icon)"
                v-for="(item, index) in report"
                @click="openInfoWindow(item)"
              >
              </GmapMarker>
            </GmapCluster>
          </GmapMap>
          <ProgressSpinner v-else />
          <div class="legend">
            <div>
              <label
                style="
                  background-color: black;
                  color: white;
                  border-radius: 5px;
                "
                >Harrows</label
              >
            </div>
            <div>
              <label style="background-color: green; border-radius: 5px"
                >Grain Carts</label
              >
            </div>
            <div>
              <label style="background-color: red; border-radius: 5px"
                >Tracks</label
              >
            </div>
            <div>
              <label style="background-color: yellow; border-radius: 5px"
                >Ditchers</label
              >
            </div>
            <div>
              <label style="background-color: orange; border-radius: 5px"
                >Fertilizer Caddy</label
              >
            </div>
            <div>
              <label style="background-color: blue; border-radius: 5px"
                >Bale Processor</label
              >
            </div>
            <div>
              <label style="background-border-color: black; border-radius: 5px"
                >Multiple Products</label
              >
            </div>
            <div>
              <label style="background-color: #d86d00; border-radius: 5px"
                >Default</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reportService from "../service/reportService";

export default {
  data() {
    return {
      reportData: "",
      report: [],
      loading: false,
      infoWinOpen: false,
      infoWindowPos: {
        lat: null,
        long: null,
      },
      visible: false,
      center: { lat: 49.1034981, lng: -97.583954999999 },
      currentPlace: null,
      selectedReport: { name: "No Report", id: null },
      reportOptions: [],
    };
  },
  reportService: null,
  created() {
    this.reportService = new reportService();
  },
  mounted() {
    this.geolocate();
    this.checkQueryString();
    this.loadReport();
    this.getReportsList();
  },
  methods: {
    /*
     * At mount, check the query string for a report Id
     * This is to accomodate requests directly from salesforce
     * When the user has pre-selected a report for viewing
     */
    checkQueryString() {
      let query = Object.assign({}, this.$route.query);
      if (query.report) {
        this.selectedReport.id = query.report;
      } else {
        this.selectedReport.name = "No Report";
        this.selectedReport.id = null;
      }
    },
    /*
     * Checks if there is a selected report id
     * If there is, send request to salesforce for that report
     * Using reportService
     */
    loadReport() {
      this.visible = false;
      if (this.selectedReport.id) {
        this.loading = true;
        this.reportService
          .getReportFromId(this.selectedReport.id)
          .then(($result) => {
            this.report = $result.data;
            this.selectedReport.name = $result.report_name;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.report = [];
      }
    },
    deleteRecord() {
      if (this.selectedReport.id) {
        console.log(this.selectedReport.name + " will be deleted NOW!");
        this.visible = false;
        this.reportService.deleteReportFromId(this.selectedReport.id);
        this.selectedReport.id = null;
        this.selectedReport.name = "No Report";
        this.getReportsList();
        this.loadReport();
      }
    },
    /*
     * called when a pin is clicked
     * makes info window visible
     * sets a location on the map
     * puts data on the info window
     */
    openInfoWindow(pos) {
      this.infoWinOpen = true;
      this.infoWindowPos.lat = pos.lat;
      this.infoWindowPos.lng = pos.lng;
      this.reportData = [];
      for (const key in pos.data) {
        this.reportData.push({ key: key, value: pos.data[key] });
      }
    },
    /*
     * Dropdown list responce
     * changes the selected report and loads it
     */
    getReportsList() {
      this.reportService.getReportsList().then(($result) => {
        this.reportOptions = $result.data;
      });
    },
    /*
     * orients the map to view Altona when map is reloaded
     */
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    /*
     * picks color of pin based on product type
     */
    getIcon(icon) {
      const baseUrl = "http://labs.google.com/ridefinder/images/";
      if (icon) {
        switch (icon) {
          case "Grain Carts":
            return `${baseUrl}mm_20_green.png`;
          case "Ditchers":
            return `${baseUrl}mm_20_yellow.png`;
          case "Harrows":
            return `${baseUrl}mm_20_black.png`;
          case "Tracks":
            return `${baseUrl}mm_20_red.png`;
          case "Bale Processor":
            return `${baseUrl}mm_20_blue.png`;
          case "Fertilizer Caddy":
            return `${baseUrl}mm_20_orange.png`;
          case "Multiple":
            return `${baseUrl}mm_20_white.png`;
          default:
            return `${baseUrl}mm_20_brown.png`;
        }
      }
    },
  },
};
</script>

<style scoped>
.p-sidebar-content {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
}
.side-bar-item {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.legend {
  display: flex;
  justify-content: space-between;
  font-size: 120%;
  width: 950px;
}
</style>