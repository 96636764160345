import BaseService from "./baseService"

export default class ReportService extends BaseService {
    getReportFromId($id) {
        return this.get('report/id/' + $id);
    }
    getReportsList()
    {
        return this.get('report/list');
    }
    deleteReportFromId($id)
    {
        return this.delete('report/delete/' + $id);
    }
}